import styled, { keyframes } from "styled-components"
import * as ThemeSettings from "../../styles/theme"

const textFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const slideIn = keyframes`
  0% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-60vw);
  }
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100%;
  background-color: #000;
  z-index: -1;
  transform: translateX(-60vw);
  animation: ${slideIn} 3s cubic-bezier(0.86, 0, 0.07, 1);

  & div {
    position: absolute;
    left: 30px;
    bottom: 20px;
    animation: ${textFadeIn} 0.8s ease;
    & h1 {
      font-size: 180px;
      line-height: 0.9;
      white-space: nowrap;
      letter-spacing: -4px;

      @media screen and (max-width: 1180px) {
        font-size: 140px;
      }

      @media screen and (max-width: 768px) {
        font-size: 100px;
      }

      @media screen and (max-width: 320px) {
        font-size: 80px;
      }

      &:nth-child(1) {
        color: #fff;
      }
      &:nth-child(2) {
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fff;
        color: #000;
      }
    }
  }
`