import React from "react"
import * as Styled from "./styled"

const Slide = props => {
  return (
    <Styled.SlideContainer
      style={{ zIndex: props.zIndex, opacity: props.opacity }}
    >
      <picture>
        <source
          media="(max-width: 1920px)"
          srcSet={ props.input.image.medium.url }
        />
        <source
          media="(max-width: 980px)"
          srcSet={ props.input.image.small.url }
        />
        <img
          alt={ props.input.image.alt ? props.input.image.alt : "Project by Mister Studio" }
          src={ props.input.image.large.url }
        />
      </picture>
    </Styled.SlideContainer>
  )
}

export default Slide