import React from "react"
import * as Styled from "./styled"

const ReelImage = props => {
  return (
    <Styled.ImageContainer
      style={{ zIndex: props.zIndex }}
    >
      <picture>
        <source
          media="(max-width: 1920px)"
          srcSet={ props.input.image.large.url }
        />
        <source
          media="(max-width: 1280px)"
          srcSet={ props.input.image.medium.url }
        />
        <source
          media="(max-width: 980px)"
          srcSet={ props.input.image.small.url }
        />
        <img
          alt={ props.input.image.alt ? props.input.image.alt : "Project by Mister Studio" }
          src={ props.input.image.large.url }
        />
      </picture>
    </Styled.ImageContainer>
  )
}

export default ReelImage