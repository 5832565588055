import styled from "styled-components"
import * as ThemeSettings from "../../styles/theme"

export const SliderContainer = styled.div`
  position: fixed;
  top: ${ThemeSettings.metrics.headerHeightDesktop};
  left: 0;
  width: 65%;
  height: calc(100vh - ${ThemeSettings.metrics.headerHeightDesktop});

  @media screen and (max-width: 1180px) {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50vh;
  }

`

export const NavContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  display: flex;
  align-items: center;

  cursor: none;

  @media screen and (max-width: 1180px) {
    cursor: pointer;
  }
`

export const Prev = styled.div`
  width: 50%;
  height: 100%;
`

export const Next = styled.div`
  width: 50%;
  height: 100%;
`

export const Copyright = styled.p`
  position: absolute;
  font-size: 14px;
  z-index: 999;
  right: ${ThemeSettings.metrics.pageDesktop};
  bottom: 26px;
  transform: rotate(90deg);
  transform-origin: top right;

  @media screen and (max-width: 1180px) {
    display: none;
  }
`

export const CountContainer = styled.p`
  position: absolute;
  left: ${ThemeSettings.metrics.pageDesktop};
  bottom: 40px;
  z-index: 999;

  &::before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #000;
    margin-right: 12px;
    transform: translateY(-1px);
  }

  @media screen and (max-width: 1180px) {
    position: absolute;
    left: ${ThemeSettings.metrics.pageMobile};
    bottom: 20px;
  }
`