import React from "react"
import { RichText } from "prismic-reactjs"
import * as Styled from "./styled"

const About = props => {

  const today = new Date()

  return (
    <Styled.AboutContainer>
      <Styled.ContentContainer>
        { RichText.render(props.input.homepage.content) }
      </Styled.ContentContainer>
      <Styled.Link
        href={ props.input.homepage.link_target[0].text }
        target="_blank"
        rel="noreferrer noopener"
      >
        <span>{ props.input.homepage.link_text[0].text }</span>
      </Styled.Link>
      <Styled.Contact>
        <a href={`tel:${props.input.homepage.phone_number_target[0].text}`}>{ props.input.homepage.phone_number_text[0].text }</a>
        <a href={`mailto:${props.input.homepage.email_target[0].text}`}>{ props.input.homepage.email_text[0].text }</a>
      </Styled.Contact>
      <Styled.Copyright>
        Mister. All Rights Reserved. { today.getFullYear() }.
      </Styled.Copyright>
    </Styled.AboutContainer>
  )
}

export default About