import React, { useState, useEffect } from "react"
import { StaticQuery, grapqhl } from "gatsby"
import ReelImage from "./image"
import Cursor from "./cursor"
import * as Styled from "./styled"

const Reel = props => {

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [currentZIndex, setCurrentZIndex] = useState(1)
  const [cursorPosition, setCursorPosition] = useState({
    x: 0,
    y: 0
  })

  const moveCursor = event => {
    setCursorPosition({
      x: event.pageX,
      y: event.pageY - window.scrollY
    })
  }

  const autoPlaySlides = () => {
    if (currentSlideIndex < images.length - 1) {
      setCurrentSlideIndex(currentSlideIndex + 1)
      setCurrentZIndex(currentZIndex + 1)
    }
    if (currentSlideIndex === images.length - 1) {
      return setCurrentSlideIndex(0)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      autoPlaySlides()
    }, 500)
    return () => clearInterval(interval)
  })

  const images = props.data.prismic.reel.images && props.data.prismic.reel.images.map((image, index) => {
    return (
      <ReelImage
        key={ index }
        input={ image }
        zIndex={ index === currentSlideIndex ? currentZIndex : currentZIndex - 1 }
      />
    )
  })

  return (
    <Styled.Container
      onMouseMove={ moveCursor }
      onClick={ props.onClick }
      style={{
        pointerEvents: props.pointerEvents
      }}
      reelIsActive={ props.reelIsActive }
    >
      <Cursor
        topPos={ cursorPosition.y + "px" }
        leftPos={ cursorPosition.x + "px" }
        {...props}
      />
      { images }
    </Styled.Container>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query ReelQuery {
        prismic {
          reel(lang: "en-us", uid: "reel") {
            images {
              image
            }
          }
        }
      }
    `}
    render={ data => <Reel data={data} {...props} /> }
  />
)