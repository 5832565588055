import React, { useState, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import Navigation from "./navigation"
import Copyright from "./copyright"
import Count from "./count"
import Slide from "../slide"
import * as Styled from "./styled"

const Slider = ({ data }) => {

  const [intervalTime, setIntervalTime] = useState(2000)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [currentZIndex, setCurrentZIndex] = useState(1)

  const goToNextSlide = () => {
    setIntervalTime(20000)
    if (currentSlideIndex === slides.length - 1) {
      return setCurrentSlideIndex(0)
    }
    setCurrentSlideIndex(currentSlideIndex + 1)
    setCurrentZIndex(currentZIndex + 1)
  }

  const goToPrevSlide = () => {
    setIntervalTime(20000)
    if (currentSlideIndex === 0) {
      return setCurrentSlideIndex(slides.length - 1)
    }
    setCurrentSlideIndex(currentSlideIndex - 1)
    setCurrentZIndex(currentZIndex + 1)
  }

  const autoPlaySlides = () => {
    if (currentSlideIndex < slides.length - 1) {
      setCurrentSlideIndex(currentSlideIndex + 1)
      setCurrentZIndex(currentZIndex + 1)
    }
    if (currentSlideIndex === slides.length - 1) {
      return setCurrentSlideIndex(0)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      autoPlaySlides()
    }, intervalTime)
    return () => clearInterval(interval)
  })

  const slides = data.prismic.projects_slider.images && data.prismic.projects_slider.images.map((image, index) => {
    return (
      <Slide
        key={ index }
        input={ image }
        zIndex={ index === currentSlideIndex ? currentZIndex : currentZIndex - 1 }
        opacity={ index === currentSlideIndex ? 1 : 0 }
      />
    )
  })

  return (
    <Styled.SliderContainer>
      <Navigation
        onPrevClick={ goToPrevSlide }
        onNextClick={ goToNextSlide }
      />
      <Count
        total={ slides.length }
        current={ currentSlideIndex + 1 }
      />
      <Copyright />
      { slides }
    </Styled.SliderContainer>
  )
}

export default props => (
  <StaticQuery
    query={ graphql`
      query ProjectsQuery {
        prismic {
          projects_slider(lang: "en-us", uid: "projects") {
            images {
              image
            }
          }
        }
      }
    `}
    render={ data => <Slider data={ data } {...props} /> }
  />
)