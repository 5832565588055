import React from "react"
import * as Styled from "./styled"

const Arrow = props => {
  return (
    <Styled.Arrow
      style={{
        display: props.visibility,
        top: props.topPos,
        left: props.leftPos,
        transform: props.rotation
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.348"
        height="19.314"
        fill="none"
        stroke="#000"
        strokeWidth="2px"
      >
        <path d="M0 9.607h20.483M11.981.707l9.66 9.66M11.981 18.607l9.66-9.66"/>
      </svg>
    </Styled.Arrow>
  )
}

export default Arrow