import styled from "styled-components"

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  z-index: 999999;
  transform: ${ props => props.reelIsActive ? "translateX(0)" : "translateX(100vw)" };
  transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);

  opacity: 1;
  pointer-events: none;
  cursor: none;

  @media screen and (max-width: 1180px) {
    cursor: pointer;
  }
`

export const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0;

  & picture {
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

export const Cursor = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  @media screen and (max-width: 1180px) {
    display: none;
  }
`