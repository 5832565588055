import styled, { keyframes } from "styled-components"

const slideIn = keyframes`
  0% {
    left: 100vw;
  }
  75% {
    left: 100vw;
  }
  100% {
    left: 0;
  }
`

export const Wrapper = styled.main`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100vw;
  max-width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transform: ${ props => props.reelIsActive ? "translateX(-60vw)" : "translateX(0)" };
  transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
  animation: ${slideIn} 3s cubic-bezier(0.86, 0, 0.07, 1);
`