import React from "react"
import * as Styled from "./styled"

const Tagline = props => {
  return (
    <Styled.Container>
      <h2>{ props.input.homepage.tagline[0].text }</h2>
    </Styled.Container>
  )
}

export default Tagline