import React from "react"
import * as Styled from "./styled"

const HomeWrapper = props => {
  return (
    <Styled.Wrapper
      reelIsActive={ props.reelIsActive }
    >
      { props.children }
    </Styled.Wrapper>
  )
}

export default HomeWrapper