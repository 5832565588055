import styled from "styled-components"
import * as ThemeSettings from "../../styles/theme"

export const Link = styled.div`
  position: absolute;
  top: 0;
  right: 35%;
  height: ${ThemeSettings.metrics.headerHeightDesktop};
  display: flex;
  align-items: center;

  @media screen and (max-width: 1180px) {
    position: absolute;
    top: calc(50vh + 100px);
    height: auto;
    left: ${ThemeSettings.metrics.pageMobile};
  }

  & a {
    span {
      display: inline-block;
      background-image: linear-gradient(transparent calc(24px - 2px), #000 0px);
      background-repeat: no-repeat;
      background-size: 100% 24px;
    }
    &:hover {
      span {
        color: ${ThemeSettings.colors.highlight};
        background-image: linear-gradient(transparent calc(24px - 2px), ${ThemeSettings.colors.highlight} 0px);
      }
      /* &::before {
        background-color: ${ThemeSettings.colors.highlight};
      } */
    }

    /* &::before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #000;
      margin-right: 12px;
      transform: translateY(-1px);
    } */
  }

`