import React, { useState } from "react"
import { ThemeProvider } from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import LayoutDefault from "../layouts/default"
import LoadingScreen from "../components/loading"
import HomeWrapper from "../components/home-wrapper"
import TextWrapper from "../components/text-wrapper"
import Title from "../components/title"
import Tagline from "../components/tagline"
import About from "../components/about"
import Slider from "../components/slider"
import ShowReelLink from "../components/show-reel-link"
import Reel from "../components/reel"

const IndexPage = ({ data }) => {

  const [reelVisibility, setReelVisibility] = useState(false)
  const [reelPointerEvents, setReelPointerEvents] = useState(false)
  const [reelCursorOpacity, setReelCursorOpacity] = useState(false)
  const [mainWrapperIsActive, setMainWrapperIsActive] = useState(true)

  const content = data.prismic

  const showReel = event => {
    event.preventDefault()
    setReelVisibility(true)
    document.body.style.overflow = "hidden"
    setMainWrapperIsActive(false)
    setReelPointerEvents(true)
    setTimeout(() => {
      setReelCursorOpacity(true)
    }, 400)
  }

  const hideReel = event => {
    if (event) {
      event.preventDefault()
    }
    setReelCursorOpacity(false)
    setReelPointerEvents(false)
    setReelVisibility(false)
    setMainWrapperIsActive(true)
    document.body.style.overflow = "initial"
  }

  return (
    <ThemeProvider theme={{ mode: "light" }}>
      <LayoutDefault>
        <LoadingScreen />
        <Reel
          onClick={ hideReel }
          // opacity={ reelVisibility ? 1 : 0 }
          pointerEvents={ reelPointerEvents ? "all" : "none" }
          reelIsActive={ reelVisibility ? true : false }
          cursorOpacity={ reelCursorOpacity ? 1 : 0 }
        />
        <HomeWrapper
          reelIsActive={ mainWrapperIsActive ? false : true }
        >
          <ShowReelLink
            onClick={ showReel }
          />
          <Title />
          <Slider />
          <TextWrapper>
            <Tagline input={ content } />
            <About input={ content } />
          </TextWrapper>
        </HomeWrapper>
      </LayoutDefault>
    </ThemeProvider>
  )

}

export default props => (
  <StaticQuery
    query={graphql`
      query HomeQuery {
        prismic {
          homepage(lang: "en-us", uid: "homepage") {
            tagline
            content
            link_text
            link_target
            phone_number_text
            phone_number_target
            email_text
            email_target
          }
        }
      }
    `}
    render={ data => <IndexPage data={data} {...props} /> }
  />
)