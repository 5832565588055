import React from "react"
import * as Styled from "./styled"

const CloseCursor = props => {
  return (
    <Styled.Cursor
      style={{
        top: props.topPos,
        left: props.leftPos,
        opacity: props.cursorOpacity
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.414"
        height="19.414"
        fill="none"
        stroke="#000"
        strokeWidth="2px"
      >
        <path d="M.707.707l18 18M18.707.707l-18 18"/>
      </svg>
    </Styled.Cursor>
  )
}

export default CloseCursor