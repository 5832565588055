import React from "react"
import * as Styled from "./styled"

const Count = props => {
  return (
    <Styled.CountContainer>
      <span>{ props.current }</span>
      <span>/</span>
      <span>{ props.total }</span>
    </Styled.CountContainer>
  )
}

export default Count