import styled from "styled-components"

export const Arrow = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 22.348px;
  height: 19.314px;
  pointer-events: none;
  transform-origin: center;
  z-index: 9999;

  @media screen and (max-width: 1180px) {
    opacity: 0;
  }
`