import styled from "styled-components"
import * as ThemeSettings from "../../styles/theme"

export const Title = styled.header`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: ${ThemeSettings.metrics.pageDesktop};
  height: ${ThemeSettings.metrics.headerHeightDesktop};

  display: flex;
  align-items: center;

  @media screen and (max-width: 1180px) {
    position: absolute;
    height: auto;
    top: ${ThemeSettings.metrics.pageMobile};
    left: ${ThemeSettings.metrics.pageMobile};
    z-index: 9999;
  }
`