import React from "react"
import * as Styled from "./styled"

const ShowReelLink = props => {
  return (
    <Styled.Link>
      <a
        onClick={ props.onClick } 
        href="#"
      >
        <span>Preview Folio</span>
      </a>
    </Styled.Link>
  )
}

export default ShowReelLink