import styled from "styled-components"

export const SlideContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: opacity 0.45s ease;

  & picture {
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`