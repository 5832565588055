import React from "react"
import * as Styled from "./styled"

const LoadingScreen = props => {

  const date = new Date()

  return (
    <Styled.Container>
      <div>
        <h1>Mister</h1>
        <h1>18–{ date.getFullYear() }</h1>
      </div>
    </Styled.Container>
  )
}

export default LoadingScreen