import styled from "styled-components"
import * as ThemeSettings from "../../styles/theme"

export const AboutContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: ${ThemeSettings.metrics.headerHeightDesktop};
  left: 0;
  width: 100%;
  min-height: 100%;
  padding: 0 ${ThemeSettings.metrics.pageDesktop};

  @media screen and (max-width: 1180px) {
    position: absolute;
    width: 100%;
    max-width: 100%;
    top: calc(50vh + 166px);
    left: 0;
    padding: 0 ${ThemeSettings.metrics.pageMobile};
  }
`

export const ContentContainer = styled.div`
  & h3 {
    font-size: 12px;
    color: ${ThemeSettings.colors.lightGrey};
    text-transform: uppercase;
    margin: 50px 0 20px;
    @media screen and (max-width: 1180px) {
      margin: 40px 0 16px;
    }
  }

  & p {
    & a {
      display: inline-block;
      background-image: linear-gradient(transparent calc(24px - 2px), #000 0px);
      background-repeat: no-repeat;
      background-size: 100% 24px;
      /* transition: all 0.45s; */
      @media screen and (min-width: 1025px) {
        &:hover {
          color: ${ThemeSettings.colors.highlight};
          background-image: linear-gradient(transparent calc(24px - 2px), ${ThemeSettings.colors.highlight} 0px);
        }
      }
    }
  }
`

export const Link = styled.a`
  margin: 80px 0 65px 0;
  display: block;

  span {
    display: inline-block;
    background-image: linear-gradient(transparent calc(24px - 2px), #000 0px);
    background-repeat: no-repeat;
    background-size: 100% 24px;
  }

  @media screen and (max-width: 1180px) {
    margin: 40px 0 30px 0;
  }

  @media screen and (min-width: 1025px) {
    &:hover {
      span {
        color: ${ThemeSettings.colors.highlight};
        background-image: linear-gradient(transparent calc(24px - 2px), ${ThemeSettings.colors.highlight} 0px);
      }
      }
    }
`

export const Contact = styled.div`
  font-size: 14px;
  padding-bottom: 30px;

  @media screen and (max-width: 1180px) {
    font-size: inherit;
    display: flex;
    flex-direction: column;
  }

  & a {
    margin-right: 20px;
    &:first-child {
      color: ${ThemeSettings.colors.lightGrey};
      @media screen and (max-width: 1180px) {
        color: inherit;
      }
    }
    &:last-child {
      transition: color 0.45s;
      &:hover {
      color: ${ThemeSettings.colors.highlight};
    }
    }
  }
`

export const Copyright = styled.p`
  display: none;

  @media screen and (max-width: 1180px) {
    display: block;

    display: block;
    font-size: 14px;
    color: #000;
    margin: 80px 0 20px;
  }
`