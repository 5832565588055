import React from "react"
import * as Styled from "./styled"

const Title = () => {
  return (
    <Styled.Title>
      <h1>Mister</h1>
    </Styled.Title>
  )
}

export default Title