import React from "react"
import * as Styled from "./styled"

const Copyright = () => {
  const today = new Date()
  return (
    <Styled.Copyright>
      Mister. All Rights Reserved. { today.getFullYear() }.
    </Styled.Copyright>
  )
}

export default Copyright