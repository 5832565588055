import React from "react"
import * as Styled from "./styled"

const TextWrapper = props => {
  return (
    <Styled.Wrapper>
      { props.children }
    </Styled.Wrapper>
  )
}

export default TextWrapper