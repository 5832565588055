import styled from "styled-components"
import * as ThemeSettings from "../../styles/theme"

export const Container = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: 0;
  width: 100%;
  height: ${ThemeSettings.metrics.headerHeightDesktop};
  padding: 0 ${ThemeSettings.metrics.pageDesktop};

  display: flex;
  align-items: center;

  @media screen and (max-width: 1180px) {
    left: 0;
    padding: 0 ${ThemeSettings.metrics.pageMobile};
    top: 50vh;
    width: 100%;
    height: 100px;
  }
`