import styled from "styled-components"

export const Wrapper = styled.div`
  position: fixed;
  left: 65%;
  width: 35%;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 1180px) {
    position: initial;
    left: 0;
    width: 100%;
    height: auto;
    max-height: auto;
  }
`