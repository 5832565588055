import React, { useState } from "react"
import Arrow from "../arrow"
import * as Styled from "./styled"

const Navigation = props => {

  const [arrowVisibility, setArrowVisibility] = useState(false)
  const [cursorPosition, setCursorPosition] = useState({
    x: 0,
    y: 0
  })
  const [cursorRotation, setCursorRotation] = useState(false)

  const moveArrow = event => {
    setCursorPosition({
      x: event.pageX,
      y: event.pageY - window.scrollY
    })
  }

  const showArrow = () => {
    setArrowVisibility(true)
  }

  const hideArrow = () => {
    setArrowVisibility(false)
  }

  const rotateCursor = () => {
    setCursorRotation(true)
  }

  const unrotateCursor = () => {
    setCursorRotation(false)
  }

  const prevMouseOver = () => {
    showArrow()
    rotateCursor()
  }

  const prevMouseOut = () => {
    hideArrow()
    unrotateCursor()
  }

  return (
    <Styled.NavContainer
      onMouseMove={ moveArrow }
    >
      <Arrow
        visibility={ arrowVisibility ? "block" : "none" }
        topPos={ cursorPosition.y + "px" }
        leftPos={ cursorPosition.x + "px" }
        rotation={ cursorRotation ? "rotate(180deg)" : "rotate(0)" }
      />
      <Styled.Prev
        onClick={ props.onPrevClick }
        onMouseOver={ prevMouseOver }
        onMouseOut={ prevMouseOut }
      />
      <Styled.Next
        onClick={ props.onNextClick }
        onMouseOver={ showArrow }
        onMouseOut={ hideArrow }
      />
    </Styled.NavContainer>
  )
}

export default Navigation